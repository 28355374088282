import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";

interface Users {
	users: any;
	userDetail: any;
	loginActivity: any;
	userQuestions: any;
	userProposals: any;
	userContests: any;
	questions: any;
	questionDetails: any;
	adminRoles: any;
	isLoading: any;
}

const initialState: Users = {
	users: {},
	userDetail: {},
	loginActivity: {},
	userQuestions: {},
	userProposals: {},
	userContests: {},
	questions: {},
	questionDetails: {},
	adminRoles: {},
	isLoading: {},
};

export const usersSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUsers: (state: Users, { payload }: PayloadAction<any>) => {
			state.users = payload?.data;
		},
		setStatusChange: (state: Users, { payload }: PayloadAction<any>) => {
			state.users = {
				...state.users,
				data: state.users?.data?.filter((user) => {
					return user?.id !== payload;
				}),
			};
		},
		setUserDetail: (state: Users, { payload }: PayloadAction<any>) => {
			state.userDetail = payload;
		},
		setLoginActivity: (state: Users, { payload }: PayloadAction<any>) => {
			state.loginActivity = payload?.data;
		},
		setUserQuestions: (state: Users, { payload }: PayloadAction<any>) => {
			state.userQuestions = payload?.data;
		},
		setUserProposals: (state: Users, { payload }: PayloadAction<any>) => {
			state.userProposals = payload?.data;
		},
		setUserContests: (state: Users, { payload }: PayloadAction<any>) => {
			state.userContests = payload?.data;
		},
		setQuestions: (state: Users, { payload }: PayloadAction<any>) => {
			state.questions = payload;
		},
		setQuestionDelete: (state: Users, { payload }: PayloadAction<any>) => {
			state.questions.data = state.questions?.data?.filter(
				(ques) => ques?.id !== payload
			);
		},
		setQuestionDetails: (state: Users, { payload }: PayloadAction<any>) => {
			state.questionDetails = payload;
		},
		setAdminRoles: (state: Users, { payload }: PayloadAction<any>) => {
			state.adminRoles = payload?.data;
		},
		setAddAdmin: (state: Users, { payload }: PayloadAction<any>) => {
			state.adminRoles = {
				...state.adminRoles,
				data: [...state.adminRoles.data, payload?.user],
			};
		},
		setSwitchAdminRole: (state: Users, { payload }: PayloadAction<any>) => {
			state.adminRoles = {
				...state.adminRoles,
				data: state.adminRoles.data.map((admin) => {
					return admin.id !== payload.id ? admin : payload?.data;
				}),
			};
		},
		setDeleteAdminUser: (state: Users, { payload }: PayloadAction<any>) => {
			state.adminRoles = {
				...state.adminRoles,
				data: state.adminRoles.data.filter((admin) => {
					return admin.id !== payload;
				}),
			};
		},
		setLoader: (state: Users, { payload }: PayloadAction<any>) => {
			state.isLoading = { ...state.isLoading, ...payload };
		},
		reset: (state: Users) => initialState,
	},
});

export const fetchUsers = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ users: true }));
	const json = await Api.users(data);
	dispatch(setLoader({ users: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setUsers(json?.data));
		}
	}
};

export const changeUserStatus = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ changeStatus: true }));
	const json = await Api.userStatusChange(data);
	dispatch(setLoader({ changeStatus: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setStatusChange(data?.id));
			toastNotification({ status: "success", message: json?.data?.message });
		}
	}
};

export const fetchUserDetail = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ userDetail: true }));
	const json = await Api.userDetail(data);
	dispatch(setLoader({ userDetail: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setUserDetail(json?.data));
		}
	}
};

export const fetchLoginActivity = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ loginActivity: true }));
	const json = await Api.loginActivities(data);
	dispatch(setLoader({ loginActivity: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setLoginActivity(json?.data));
		}
	}
};

export const fetchUserQuestions = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ userQuestions: true }));
	const json = await Api.userQuestions(data);
	dispatch(setLoader({ userQuestions: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setUserQuestions(json?.data));
		}
	}
};

export const fetchUserProposals = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ userProposals: true }));
	const json = await Api.userProposals(data);
	dispatch(setLoader({ userProposals: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setUserProposals(json?.data));
		}
	}
};

export const fetchUserContests = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ userContests: true }));
	const json = await Api.userContests(data);
	dispatch(setLoader({ userContests: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setUserContests(json?.data));
		}
	}
};

export const fetchTransactions = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ transactions: true }));
	const json = await Api.transactions(data);
	dispatch(setLoader({ transactions: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setQuestions(json?.data?.AllQuestion));
		}
	}
};

export const fetchQuestionDetails = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ questionDetails: true }));
	const json = await Api.questionDetails(data);
	dispatch(setLoader({ questionDetails: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setQuestionDetails(json?.data));
		}
	}
};

export const deleteQuestion = (id: any) => async (dispatch: any) => {
	dispatch(setLoader({ deleteQues: id }));
	const json = await Api.deleteQuestion(id);
	dispatch(setLoader({ deleteQues: null }));
	if (!!json) {
		if (json.status === 200) {
			toastNotification({ status: "success", message: "Discussion Deleted!" });
			dispatch(setQuestionDelete(id));
		}
	}
};

export const fetchAdminRoles = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ adminRoles: true }));
	const json = await Api.adminRoles(data);
	dispatch(setLoader({ adminRoles: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setAdminRoles(json?.data));
		}
	}
};

export const addAdmin = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ addAdmin: true }));
	const json = await Api.addAdmin(data);
	dispatch(setLoader({ addAdmin: false }));
	if (json !== undefined) {
		if (json.status === 200) {
			dispatch(setAddAdmin(json?.data));
			toastNotification({ status: "success", message: "Admin Added!" });
			data.setOpen(false);
		} else if (json?.response?.status === 422) {
			dispatch(setLoader({ addAdmin: false }));
			toastNotification({
				status: "warning",
				message: "User Field or Role is missing!",
			});
		}
	}

	return json;
};

export const switchAdminRole = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ switchAdminRole: true }));
	const json = await Api.switchAdminRole(data);
	dispatch(setLoader({ switchAdminRole: false }));
	if (json) {
		if (json.status === 200) {
			dispatch(
				setSwitchAdminRole({
					data: json?.data?.data,
					id: data?.params?.user_id,
				})
			);
			toastNotification({
				status: "success",
				message: `Role Switched to ${json?.data?.data?.role?.name}`,
			});
		}
	}
};

export const deleteAdmin = (data: any) => async (dispatch: any) => {
	dispatch(setLoader({ deleteAdmin: true }));
	const json = await Api.deleteAdmin(data);
	dispatch(setLoader({ deleteAdmin: false }));
	if (!!json) {
		if (json.status === 200) {
			dispatch(setDeleteAdminUser(data));
			toastNotification({ status: "success", message: "Admin Deleted!" });
		}
	}
};
export const {
	setUsers,
	setStatusChange,
	setUserDetail,
	setLoginActivity,
	setUserQuestions,
	setUserProposals,
	setUserContests,
	setQuestions,
	setQuestionDelete,
	setQuestionDetails,
	setAdminRoles,
	setAddAdmin,
	setSwitchAdminRole,
	setDeleteAdminUser,
	setLoader,
	reset: resetUsers,
} = usersSlice.actions;
export default usersSlice.reducer;
