import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteQuestion, fetchTransactions } from "../../../redux/users.ts";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../../components/loader/index.js";
import { Pagination } from "../../../components/pagination/index.js";
import { FilterBtn } from "../../../components/filterBtn/index.js";
import { FilterField } from "../../../components/filterField/index.js";
import { Unavailable } from "../../../components/noData/index.js";
import { convertDateToWords } from "../../../helper/dateFormat.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const OverallUsers = () => {
	const [params, setParams] = useState({ status: 1 });
	const navigate = useNavigate();
	const { questions, isLoading } = useSelector((store) => store.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (questions?.current_page) {
			dispatch(
				fetchTransactions({
					params: { ...params, page: questions?.current_page },
				})
			);
		} else {
			dispatch(fetchTransactions({ params: params }));
		}
	}, []);

	function pageChange(page) {
		dispatch(fetchTransactions({ params: { ...params, page: page } }));
	}

	const handleClearDate = () => {
		let param = { ...params, endDate: "", startDate: "" };
		setParams(param);
		dispatch(fetchTransactions({ params: param }));
	};

	const handleClear = () => {
		let param = {};
		setParams(param);
		dispatch(fetchTransactions({ params: param }));
	};

	const handleSearch = () => dispatch(fetchTransactions({ params: params }));

	const handleDeleteDiscssion = (question) => {
		dispatch(deleteQuestion(question?.id));
	};

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "Discussions", link: "" }]} />
			<div className="relative mx-12">
				<div className="flex justify-start items-center space-x-5 my-2">
					<FilterField
						param={params}
						setParam={setParams}
						type={"date range"}
					/>
					<div className="mt-7">
						<FilterBtn
							func={handleClearDate}
							disabled={!params?.startDate && !params?.endDate}
							text={"Clear"}
							color={"red"}
						/>
					</div>
					<FilterField param={params} setParam={setParams} type={"name"} />
					<div className="mt-7">
						<FilterBtn
							func={handleClear}
							disabled={!params?.name && !params?.startDate && !params?.endDate}
							text={"Clear"}
							color={"red"}
						/>
					</div>
					<div className="mt-7">
						<FilterBtn
							func={handleSearch}
							disabled={
								!params?.name && (!params?.startDate || !params?.endDate)
							}
						/>
					</div>
				</div>
				<div className="h-[calc(100vh-280px)] overflow-auto">
					<table className="w-full text-sm text-left text-gray-500 border border-black">
						<thead className="text-sm text-orange-500 uppercase bg-gray-100 border border-black">
							<tr>
								<th scope="col" className="px-6 py-3 text-center">
									#
								</th>
								<th scope="col" className="px-6 py-3 text-center">
									Discussions
								</th>
								<th scope="col" className="px-6 py-3 text-center">
									Submission Date
								</th>
								<th scope="col" className="px-6 py-3 text-center">
									Action
								</th>
							</tr>
						</thead>
						{isLoading?.transactions ? (
							<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								<Loader w="20" h="20" color={"orange"} />
							</div>
						) : (
							<tbody>
								<Unavailable data={questions} />
								{questions?.data?.map((question, index) => {
									return (
										<tr key={index} className="bg-white hover:bg-gray-50">
											<td className="px-6 py-4 text-center">
												{questions?.from + index}
											</td>
											<td className="px-6 py-4 text-center">
												<button
													onClick={() =>
														navigate(`/users/question-details/${question?.id}`)
													}
													className="font-[700]"
												>
													{question?.users?.firstname +
														" " +
														question?.users?.lastname}
												</button>{" "}
												has posted a question{" "}
											</td>
											<td className="px-6 py-4 text-center">
												{convertDateToWords(question?.created_at?.slice(0, 10))}
											</td>
											<td className="px-6 py-4 text-center hover:text-blue-600 flex justify-center items-center gap-2">
												<button
													onClick={() => handleDeleteDiscssion(question)}
													className="py-2 text-red-600 border-2 rounded-lg px-2 border-red-600 bg-red hover:bg-red-600 hover:text-white transition-all duration-200 ease-in-out"
												>
													{isLoading?.deleteQues === question?.id ? (
														<Loader w={"5"} h={"5"} color={"red"} />
													) : (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth="1.5"
															stroke="currentColor"
															className="w-5 h-5"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
															/>
														</svg>
													)}
												</button>
												<button
													onClick={() =>
														navigate(`/users/question-details/${question?.id}`)
													}
													className="py-2 text-blue-600 border-2 rounded-lg px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
												>
													View
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						)}
					</table>
				</div>
				<Pagination
					total={questions?.last_page}
					current={questions?.current_page}
					func={pageChange}
				/>
			</div>
		</>
	);
};
