import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DraftEditor = ({ title, onChange, limit = 10000 }) => {
  const [value, setValue] = useState("");

  useEffect(() => setValue((title || "").replace(/\n/g, "<br />")), [title]);

  useEffect(() => onChange(value), [value]); // Update onChange without an empty string check

  const getPlanText = (content) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content;
    return tempElement.innerText || tempElement.textContent || "";
  };

  const handleChange = (content) => {
    const plainText = getPlanText(content);
    if (plainText.length > limit) {
      const truncatedText = plainText.slice(0, limit);
      content = truncatedText;
    }

    setValue(content);
    onChange(content);
  };

  return (
    <div className="w-full flex flex-col justify-end items-end relative">
      <ReactQuill
        className="w-full"
        theme="snow"
        value={value}
        onChange={handleChange}
      />
      <div className="text-[11px] absolute -top-6">
        ({getPlanText(value)?.length}/{limit})
      </div>
    </div>
  );
};

export default DraftEditor;
